import "../Styles/index.scss";

import mediumZoom from "@wagich/medium-zoom";
import Flickity from "flickity";
import "flickity-as-nav-for";
import svelteRetag from "svelte-retag";

import { trackEvent, trackPageview } from "./analytics";
import { Tabs } from "./tabs";
import NewsletterSignup from "./components/newsletter-signup.svelte";

new svelteRetag({
	component: NewsletterSignup,
	tagname: "newsletter-signup"
});

for (let container of document.querySelectorAll<HTMLElement>(".contentTabs")) {
	new Tabs(container);
}

const zoomOptions = {
	background: "rgba(255,255,255,0.8)",
	margin: Math.max(window.innerHeight, window.innerWidth) * 0.03, // 3vmax
};

let zoom = mediumZoom(".asset.is-zoomable:not(.asset-gallery__image)", zoomOptions);

for (let gallery of document.querySelectorAll<HTMLElement>(".asset-gallery")) {
	let galleryElement = gallery.querySelector<HTMLElement>(".asset-list.is-asset-gallery-large");
	let galleryNavElement = gallery.querySelector<HTMLElement>(".asset-list.is-asset-gallery-thumbnail");
	if (galleryElement != null && galleryNavElement != null) {
		let galleryFlickity = new Flickity(galleryElement, {
			cellAlign: "left",
			pageDots: false,
			contain: true,
			wrapAround: true,
			lazyLoad: 1,
		});
		galleryFlickity.on("staticClick", e => {
			let target = e?.target as HTMLElement;
			window.setTimeout(() => {
				let zoom = mediumZoom(target, zoomOptions);
				zoom.open({ target });
				zoom.detach(target);
			}, 0);
		});

		new Flickity(galleryNavElement, {
			pageDots: false,
			lazyLoad: 8,
			contain: true,
			asNavFor: galleryElement,
		});
	}
}

trackPageview();
for (let element of document.querySelectorAll("a[href^='mailto'],a[href^='tel']")) {
	element.addEventListener("click", e => {
		trackEvent("Kontaktlink aktiviert");
	});
}
