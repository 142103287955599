const ActiveClass = "is-active";

export class Tabs {
	tabs: HTMLElement[];
	contents: HTMLElement[];

	constructor(container: HTMLElement) {
		this.tabs = Array.from(container.querySelectorAll(".tabs li"));
		this.contents = Array.from(container.querySelectorAll(".tab-content"));

		container.querySelector(".tabs")?.addEventListener("click", this.handleTabsClick.bind(this));

		let initialActiveTab = this.tabs.find(t => t.classList.contains(ActiveClass));
		if (initialActiveTab != null) {
			this.activateTab(initialActiveTab);
		}
	}

	handleTabsClick(e: Event) {
		let target = e.target as HTMLElement;
		let tab = target?.closest<HTMLElement>("li");
		if (tab == null) {
			return;
		}

		this.deactivateAll();
		this.activateTab(tab);
	}

	deactivateAll() {
		for (let tab of this.tabs) {
			tab.classList.remove(ActiveClass);
		}
		for (let content of this.contents) {
			content.classList.remove(ActiveClass);
		}
	};

	activateTab(tab: HTMLElement) {
		tab.classList.add(ActiveClass);

		let index = this.tabs.indexOf(tab);
		this.contents[index].classList.add(ActiveClass);
	}
}
