<script lang="ts">
	enum State {
		Loading = "loading",
		Ready = "ready",
		ActionSuccess = "actionSuccess",
		ActionError = "actionError",
		Error = "error",
	}
	
	import { Button } from "svelma";
	import { Notification } from "svelma";
    import { trackEvent } from "../analytics";
	
	let state: State = State.Ready;
	let name: string = "";
	let email: string = "";

	async function register() {
		state = State.Loading;
		try {
			let response = await fetch("/api/newsletter", {
				method: "POST", 
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				},
				body: JSON.stringify({ Name: name, Email: email })
			});
			if (response.ok) {
				state = State.ActionSuccess;
				name = "";
				email = "";
				trackEvent("Newsletteranmeldung");
			} else {
				state = State.ActionError;
			}
		} catch (e) {
			state = State.ActionError;
		}
	}
</script>

<div class="newsletter-signup">
	<h2 class="title newsletter__title">Newsletter anmelden</h2>
	<p>In unserem Newsletter erhalten Sie nützliche saisonale Tipps und erfahren viel Wissenswertes rund um Gartengestaltung und Pflanzenpflege. Dazu Neuigkeiten aus unserem Betrieb, Spezialaktionen oder eine exklusive Einladung zu einer Garten-Ausstellung.</p>
	<form class="columns mt-0" on:submit|preventDefault={register}>
		<div class="column">
			<div class="field">
				<label class="label" for="name">Vor- und Nachname</label>
				<div class="control">
					<input id="name" class="input" type="text" autocomplete="name" placeholder="Max Muster" required bind:value={name} />
				</div>
			</div>
		</div>
		<div class="column">
			<div class="field">
				<label class="label" for="email">E-Mail</label>
				<div class="control">
					<input id="email" class="input" type="email" autocomplete="email" placeholder="max@example.com" required bind:value={email} />
				</div>
			</div>
		</div>
		<div class="column is-narrow is-flex is-align-items-flex-end">
			<div class="field">
				<div class="control">
					<Button type="is-link" nativeType="submit" loading={state === State.Loading}>Anmelden</Button>
				</div>
			</div>
		</div>
	</form>

	<Notification type="is-success" active={state === State.ActionSuccess}>
		Sie haben sich erfolgreich für unseren Newsletter angemeldet, herzlichen Dank.
	</Notification>
	<Notification type="is-danger" active={state === State.ActionError}>
		Bei der Newsletter-Anmeldung ist ein Fehler aufgetreten.
	</Notification>
</div>
